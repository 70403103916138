<template>
  <div class="bg-gray-200">
    <div class="container mx-auto grid grid-cols-2 w-full">
      <div class="py-2 text-md">
        <router-link to="/" class="text-green hover:underline"
          >Dashboard</router-link
        >
        <span class="text-gray-400"> / </span>
        <span class="font-bold">Quotes</span>
      </div>
      <span class="text-right font-bold block text-md">
        <a
          class="cursor-pointer inline-block h-full py-2 text-green hover:underline"
          v-if="!archived"
          @click="loadQuotes(true)"
          >Show Archived</a
        >
        <a
          class="cursor-pointer inline-block h-full py-2 text-green hover:underline"
          v-if="archived"
          @click="loadQuotes(false)"
          >Show Active</a
        >
        <span class="inline-block p-2 text-gray-400"> | </span>
        <router-link
          class="cursor-pointer font-bold inline-block h-full py-2 text-green hover:underline"
          to="/quotes/create"
          >Create Quote Request</router-link
        >
      </span>
    </div>
  </div>
  <div class="container mx-auto py-5">
    <div class="mb-5" v-if="sales_funnel.length > 0">
      <h3 class="text-lg font-medium leading-6 text-gray-900">
        Sales Funnel. Last 365 days.
      </h3>
      <dl
        class="mt-5 grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-6 md:divide-y-0 md:divide-x"
      >
        <template v-for="item in sales_funnel" :key="item.id">
          <div class="px-4 py-5 sm:p-6">
            <dt class="text-base font-normal text-gray-900">
              {{ item.status_description }}
            </dt>
            <dd
              class="mt-1 flex items-baseline justify-between md:block lg:flex"
            >
              <div
                class="flex items-baseline text-2xl font-semibold"
                :class="classesForStatus(item)"
              >
                $ {{ item.potential_value }}
              </div>
            </dd>
          </div>
        </template>
      </dl>
    </div>
    <div class="shadow bg-gray-100 mb-5">
      <div class="px-5 py-2 border-b border-gray-200 bg-gray-50">
        <span class="font-bold">Quotes</span>
        <span class="block text-xs text-gray-400">Assigned to you</span>
      </div>
      <div v-if="userQuotes.length > 0">
        <QuotesTable :quotes="userQuotes" />
      </div>
      <div v-if="userQuotes.length == 0 && !loadingQuotes">
        <span class="block text-sm italic px-5 py-2 text-gray-400"
          >No quotes assigned to you</span
        >
      </div>
    </div>

    <div class="shadow bg-gray-100">
      <div class="px-5 py-2 border-b border-gray-200 bg-gray-50">
        <span class="font-bold">Quotes</span>
        <span class="block text-xs text-gray-400"
          >Assigned to someone in your company</span
        >
      </div>
      <div v-if="companyQuotes.length > 0">
        <QuotesTable :quotes="companyQuotes" />
      </div>
      <div v-if="companyQuotes.length == 0 && !loadingQuotes">
        <span class="block text-sm italic px-5 py-2 text-gray-400"
          >No quotes assigned to your company</span
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import QuotesTable from "@/components/QuotesTable.vue";
import axios from "axios";

export default {
  components: {
    QuotesTable,
  },

  data: () => {
    return {
      userQuotes: [],
      companyQuotes: [],
      loadingQuotes: true,
      archived: false,
      sales_funnel: [],
    };
  },

  computed: {
    ...mapGetters({
      quotes: "quotes/rows",
      user: "user",
    }),
  },

  created: function () {
    document.title = "MLE Rep Center | Quotes";
    this.loadQuotes();
    this.getSalesFunnel();
  },

  methods: {
    classesForStatus(status) {
      if (status.status_id === 4) {
        return "text-green-600";
      }

      if (status.status_id === 5) {
        return "text-red-600";
      }

      return "text-gray-600";
    },

    async getSalesFunnel() {
      const resp = await axios.get("api/rep-center/me/quotes/sales-funnel");
      this.sales_funnel = resp.data.sales_funnel;
    },

    async loadQuotes(archived = false) {
      this.archived = archived;
      this.loadingQuotes = true;
      await this.$store.dispatch("quotes/load", archived);
      this.parseQuotes();
      this.loadingQuotes = false;
    },
    parseQuotes() {
      this.userQuotes = [];
      this.companyQuotes = [];
      for (let s = 0; s < this.quotes.length; s++) {
        if (this.isUserQuote(this.quotes[s])) {
          this.userQuotes.push(this.quotes[s]);
        } else {
          this.companyQuotes.push(this.quotes[s]);
        }
      }
    },
    isUserQuote(quote) {
      for (let r = 0; r < quote.rep_users.length; r++) {
        let rep = quote.rep_users[r];
        if (rep.id === this.user.id) {
          return true;
        }
      }
      return false;
    },
  },
};
</script>
