<template>
  <table class="w-full table-fixed">
    <thead>
      <tr class="bg-gray-100 border-b border-gray-200 text-xs">
        <th class="text-left w-3/12">
          <a
            class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200"
            @click="changeSort('name')"
            >Name
            <ChevronDownIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'name' && sortDir == 'desc'"
            />
            <ChevronUpIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'name' && sortDir == 'asc'"
            />
          </a>
        </th>
        <th class="text-left">
          <a
            class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200"
            @click="changeSort('number')"
            >Number
            <ChevronDownIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'number' && sortDir == 'desc'"
            />
            <ChevronUpIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'number' && sortDir == 'asc'"
            />
          </a>
        </th>
        <th class="text-left">Customer</th>
        <th class="text-left">
          <a
            class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200"
            @click="changeSort('potential_value')"
            >Potential Value
            <ChevronDownIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'potential_value' && sortDir == 'desc'"
            />
            <ChevronUpIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'potential_value' && sortDir == 'asc'"
            />
          </a>
        </th>
        <th class="text-left">
          <a
            class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200"
            @click="changeSort('status')"
            >Status
            <ChevronDownIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'status' && sortDir == 'desc'"
            />
            <ChevronUpIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'status' && sortDir == 'asc'"
            />
          </a>
        </th>
        <th class="text-left">
          <a
            class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200"
            @click="changeSort('created_at')"
            >Created
            <ChevronDownIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'created_at' && sortDir == 'desc'"
            />
            <ChevronUpIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'created_at' && sortDir == 'asc'"
            />
          </a>
        </th>
        <th class="text-left">
          <a
            class="px-5 py-1 block w-full cursor-pointer hover:bg-gray-200"
            @click="changeSort('updated_at')"
            >Updated
            <ChevronDownIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'updated_at' && sortDir == 'desc'"
            />
            <ChevronUpIcon
              class="inline-block h-4 w-4 text-green"
              v-if="sort == 'updated_at' && sortDir == 'asc'"
            />
          </a>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr
        class="border-b border-gray-200 text-sm"
        v-for="quote in allQuotes"
        v-bind:key="quote.id"
      >
        <td class="px-5 py-2">
          <router-link
            class="text-green hover:underline"
            :to="`/quotes/${quote.id}`"
            >{{ quote.name }}</router-link
          >
        </td>
        <td class="px-5 py-2">{{ quote.number }}</td>
        <td class="px-5 py-2">
          {{ quote.customer.company_name ?? quote.customer.first_name }}
        </td>
        <td class="px-5 py-2">
          <span v-if="quote.potential_value != null"
            >${{
              Number(quote.potential_value).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })
            }}</span
          >
        </td>
        <td class="px-5 py-2">{{ quote.status_text }}</td>
        <td class="px-5 py-2">{{ parseDate(quote.created_at) }}</td>
        <td class="px-5 py-2">{{ parseDate(quote.updated_at) }}</td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/solid";

export default {
  components: { ChevronDownIcon, ChevronUpIcon },
  data: () => {
    return {
      sort: "updated_at",
      sortDir: "desc",
    };
  },

  props: {
    quotes: Array,
  },

  computed: {
    allQuotes() {
      let quotes = this.quotes;
      return quotes.sort(this.compare);
    },
  },

  methods: {
    parseDate(date) {
      let d = new Date(date);
      return d.toLocaleDateString();
    },

    changeSort(sort = null) {
      if (!this.allQuotes) return;
      if (this.sort === sort) {
        this.sortDir = this.sortDir == "asc" ? "desc" : "asc";
      } else {
        this.sort = sort;
        this.sortDir = "desc";
      }
      this.allQuotes.sort(this.compare);
    },

    compare(a, b) {
      let aVal = a[this.sort];
      let bVal = b[this.sort];
      if (!isNaN(aVal) || !isNaN(bVal)) {
        aVal = aVal ? parseFloat(aVal) : 0;
        bVal = bVal ? parseFloat(bVal) : 0;
      }
      if (aVal > bVal) return this.sortDir == "asc" ? 1 : -1;
      if (aVal < bVal) return this.sortDir == "asc" ? -1 : 1;
      return 0;
    },
  },
};
</script>
